import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// import { getAuth } from 'firebase/auth';
// import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

const firebaseApp = initializeApp({
  apiKey: "AIzaSyA15nD6I2IpFxSGgsxwQJLAfqu-m4KDByE",
  authDomain: "la-galigo-liveboard.firebaseapp.com",
  projectId: "la-galigo-liveboard",
  storageBucket: "la-galigo-liveboard.appspot.com",
  messagingSenderId: "1022196225788",
  appId: "1:1022196225788:web:cdd064503379b15b8da979",
  measurementId: "G-N6CNYBRNSK"
});

getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
// export const auth = getAuth(firebaseApp);
// export const storage = getStorage(firebaseApp);
export const fn = getFunctions(firebaseApp);
export const anal = getAnalytics(firebaseApp);

export default firebaseApp;