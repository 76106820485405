import React, { Component } from 'react';
import { ReactComponent as Logo } from '../assets/img/icon.svg';

class Loading extends Component{
  constructor(){
    super();
    
    this.state = {}
  }

  componentDidMount(){}

  componentDidUpdate(prevProps, prevState){}

  componentWillUnmount(){}

  render(){
    if(this.props.section){
      return(
        <div className="loading-sec">
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      );
    }else{
      return(
        <div className="loading">
          <Logo />
          Loading...
        </div>
      );
    }
  }
}

export default Loading;