import React, { Component, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import makeCancellablePromise from 'make-cancellable-promise';
import { collection, limit, query, getDocs, addDoc, where, orderBy } from 'firebase/firestore';
import { toast } from 'react-toastify';
import smoothscroll from 'smoothscroll-polyfill'
import { FaCaretDown, FaEquals, FaTimes } from 'react-icons/fa';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';

import Loading from './../components/Loading.js';
import withRouter from './../helper/withrouter.js';
import { db } from './../helper/firebase';
import { ReactComponent as LogoRed } from './../assets/img/logo-red.svg';
import { ReactComponent as LogoWhite } from './../assets/img/logo-white.svg';
import { ReactComponent as Email } from './../assets/img/icon/email.svg';
import { ReactComponent as Wa } from './../assets/img/icon/wa.svg';
import { ReactComponent as Visa } from './../assets/img/icon/payment/visa.svg';
import { ReactComponent as Master } from './../assets/img/icon/payment/master.svg';
import { ReactComponent as Paypal } from './../assets/img/icon/payment/paypal.svg';
import { ReactComponent as Wise } from './../assets/img/icon/payment/wise.svg';
import { ReactComponent as Bitcoin } from './../assets/img/icon/payment/bitcoin.svg';
import { ReactComponent as Eth } from './../assets/img/icon/payment/eth.svg';
import { ReactComponent as Xrp } from './../assets/img/icon/payment/xrp.svg';
import { ReactComponent as Ig } from './../assets/img/icon/social/ig.svg';
import { ReactComponent as Fb } from './../assets/img/icon/social/fb.svg';
import { ReactComponent as Gl } from './../assets/img/icon/social/gl.svg';
import { ReactComponent as Yt } from './../assets/img/icon/social/yt.svg';
import { ReactComponent as Tw } from './../assets/img/icon/social/tw.svg';
import { ReactComponent as In } from './../assets/img/icon/social/in.svg';
import { ReactComponent as Pt } from './../assets/img/icon/social/pt.svg';
import { ReactComponent as Bl } from './../assets/img/icon/social/bl.svg';


// import logo from './../logo.svg';
// import './App.css';

const 
      // Home = React.lazy(() => {
      //   return new Promise(resolve => {
      //     setTimeout(() => resolve(import('./Home/Home')), 3000000);
      //   });
      // }),
      Home = React.lazy(() => import('./Home/Home')),
      Booking = React.lazy(() => import('./Booking/Booking')),
      Result = React.lazy(() => import('./Result/Result'));

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      generalLoad: false,
      general: {
        decimal: '',
        thousand: '',
        currency: '',
        maxItem: '',
        showYear: '',
        heroImage: '',
        heroText: '',
        contact: '',
        privacy: '',
        inex: '',
        address: ''
      },
      destinationLoad: false,
      destinationList: [],
      book: '',
      order: '',
      ip: '',
      open: false,
      more: false
    }

    this.cancellableGeneral = '';
    this.cancellableIp = '';

    this.loadGeneral = this.loadGeneral.bind(this);
    this.loadDestination = this.loadDestination.bind(this);
    this.openNav = this.openNav.bind(this);
    this.moreNav = this.moreNav.bind(this);
    this.makeBook = this.makeBook.bind(this);
    this.makeOrder = this.makeOrder.bind(this);
  }

  componentDidMount(){
    TagManager.initialize({
      gtmId: 'GTM-WSNJ9Q4',
      events: {
        trip_enquiry: 'Trip Enquiry',
      }
    });
    
    ReactGA.initialize('G-50DD8PSVWV');

    smoothscroll.polyfill();
    
    this.loadGeneral();
    this.loadDestination();

    this.cancellableIp = makeCancellablePromise(
      fetch('https://geolocation-db.com/json/')
    );

    this.cancellableIp.promise.then((res) => res.json()).then((data) => {
      this.setState({
        ip: data
      });
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.router.location.pathname !== prevProps.router.location.pathname){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  componentWillUnmount(){
    if(this.cancellableGeneral){
      this.cancellableGeneral.cancel();
    }

    if(this.cancellableDestination){
      this.cancellableDestination.cancel();
    }

    if(this.cancellableIp){
      this.cancellableIp.cancel();
    }

    if(this.cancellableLog){
      this.cancellableLog.cancel();
    }
  }

  openNav(){
    if(this.state.open){
      this.setState({
        open: false,
        more: false
      });
    }else{
      this.setState({ open: true });
    }
  }

  moreNav(){
    if(this.state.more){
      this.setState({ more: false });
    }else{
      this.setState({ more: true });
    }
  }

  loadGeneral(){
    this.setState({ generalLoad: true });

    this.cancellableGeneral = makeCancellablePromise(
      getDocs(
        query(
          collection(db, 'general'), limit(1)
        )
      )
    );

    this.cancellableGeneral.promise.then((generalSnapshot) => {
      if(generalSnapshot.size){
        const generalData = generalSnapshot.docs[0].data();
        
        this.setState({
          generalLoad: false,
          general: {
            decimal: generalData.generalDecimalSeparator,
            thousand: generalData.generalThousandSeparator,
            currency: generalData.generalCurrency,
            maxItem: generalData.generalLoad,
            showYear: generalData.generalYear,
            heroImage: generalData.generalHeroImage,
            heroText: generalData.generalHeroText,
            contact: generalData.generalContact,
            privacy: generalData.generalPrivacy,
            inex: generalData.generalInEx,
            address: generalData.generalAddress,
            email: generalData.generalEmail
          }
        });
      }else{
        this.setState({ generalLoad: false });
      }
    }).catch((error) => {
      console.error(error);

      toast.error(() => (<>{error.code}<span>{error.message}</span></>));

      this.setState({ generalLoad: false });
    });
  }

  loadDestination(){
    this.setState({ destinationLoad: true });

    this.cancellableDestination = makeCancellablePromise(
      getDocs(
        query(
          collection(db, 'destination'), where('destinationStatus', '==', true), where('destinationDisplay', '==', true), orderBy('destinationLength', 'desc')
        )
      )
    );

    this.cancellableDestination.promise.then((destinationSnapshot) => {
      if(destinationSnapshot.size){
        let listDestination = [];

        destinationSnapshot.forEach((destinationDoc) => {
          const destinationData = destinationDoc.data();
          
          listDestination.push({
            id: destinationDoc.id,
            name: destinationData.destinationName,
            length: destinationData.destinationLength,
            pdf: destinationData.destinationPdf,
            trip: typeof destinationData.destinationTrip !== 'undefined' ? destinationData.destinationTrip : true,
          });
        });

        this.setState({
          destinationList: listDestination,
          destinationLoad: false
        });
      }else{
        this.setState({ destinationLoad: false });
      }
    }).catch((error) => {
      console.error(error);

      toast.error(() => (<>{error.code}<span>{error.message}</span></>));

      this.setState({ destinationLoad: false });
    });
  }

  makeBook(value){
    let cabin = [];

    value.cabin.forEach((valCab, indCab) => {
      if(valCab.qty){
        let qty = valCab.qty;
        
        if(valCab.qty % valCab.book){
          qty++;
        }

        cabin.push({
          id: valCab.id,
          name: valCab.name,
          price: valCab.price,
          disc: valCab.disc,
          priceDisc: valCab.price - valCab.discAmount,
          qty: valCab.qty,
          book: valCab.book,
          total: qty * valCab.price,
          totalDisc: qty * valCab.total
        });
      }
    });

    this.setState({
      book: {
        id: value.id,
        name: value.name,
        destination: value.destination,
        dateStart: value.dateStart,
        dateEnd: value.dateEnd,
        day: value.length,
        cabin: cabin,
        qty: value.summary.qty,
        price: value.summary.price,
        groupDisc: value.groupDisc,
        discGroup: value.summary.discGroup,
        disc: value.summary.disc,
        total: value.summary.total,
      }
    });

    this.props.router.navigate('/booking');
  }

  makeOrder(id, invoice, name, phone, email, trip, guest, note, date){
    this.cancellableLog = makeCancellablePromise(
      addDoc(collection(db, 'log'), {
        logAct: 'db',
        logName: 'Booking',
        logDesc: 'Add book/' + id ,
        logAgent: window.navigator.userAgent,
        logIp: this.state.ip,
        logTime: new Date()
      })
    );

    this.cancellableLog.promise.then(() => {
      // toast.success(() => (<>Adding {this.props.nav.name} Successfully!<span>{this.props.nav.name} <strong>{this.state.number}</strong> person discount already <strong>added</strong>.</span></>));
    }).catch((error) => {
      console.error(error);
      // toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });

    this.setState({
      book: '',
      order: {
        invoice: invoice,
        date: date,
        name: name,
        phone: phone,
        email: email,
        trip: trip,
        guest: guest,
        note: note,
      }
    });

    this.props.router.navigate('/result');
  }

  render(){
    return (
      <>
        <Helmet>
          <title>La Galigo Liveaboard</title>
        </Helmet>

        <header>
          <button type="button" onClick={this.openNav} className="navmobile">
            {this.state.open === true ? (
              <FaTimes />
            ) : (
              <FaEquals />
            )}
          </button>
          <a href="https://www.lagaligoliveaboard.com/">
            <LogoRed />
          </a>
          <ul className="floating">
            <li className="lay-85">
              <ul className={this.state.open === true ? 'open' : ''}>
                <li>
                  <a href="https://www.lagaligoliveaboard.com/">Home</a>
                </li>
                <li>
                  <a href="https://www.lagaligoliveaboard.com/the-boat/">The Boat</a>
                </li>
                <li>
                  <a href="https://www.lagaligoliveaboard.com/trips/">Trips</a>
                </li>
                <li>
                  <a href="https://trip.lagaligoliveaboard.com/" className="active">Schedule & Rates</a>
                </li>
                <li>
                  <a href="https://www.lagaligoliveaboard.com/contact-la-galigo/">Contact Us</a>
                </li>
                <li className={this.state.more === true ? 'more' : ''}>
                  <button type="button" onClick={this.moreNav}>More<FaCaretDown /></button>
                  <ul>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/special-offers/">Special Offers</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/news-articles/">News and Articles</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/gallery-updates/">Gallery and Updates</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/guests-reviews/">Guests Reviews</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/crew-onboard/">Crew Onboard</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/conservation/">Conservation</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/getting-to-komodo/">Getting To Komodo</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/getting-to-raja-ampat/">Getting To Raja Ampat</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/covid19-health-protocols/">Covid-19 Health Protocols</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/frequently-asked-questions/">Frequently Asked Questions</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="lay-15">
                <a href="https://lagaligoliveaboard.com/contact-la-galigo">
                  <span>Enquire</span>
                </a>
            </li>
          </ul>
        </header>

        <div className="container">
          {this.state.generalLoad ? (
            <Loading />
          ) : this.state.general.showYear ? (
            <Suspense fallback={
              <Loading />
            }>
              <Routes>
                <Route path="/" element={
                  <Home router={this.props.router} general={this.state.general} makeBook={this.makeBook} destinationLoad={this.state.destinationLoad} destinationList={this.state.destinationList} />
                } />
                <Route path="/booking" element={
                  <Booking router={this.props.router} general={this.state.general} book={this.state.book} makeOrder={this.makeOrder} />
                } />
                <Route path="/result" element={
                  <Result router={this.props.router} general={this.state.general} order={this.state.order} />
                } />
                <Route path="*" element={
                  <Navigate to="/" />
                } />
              </Routes>
            </Suspense>
          ) : (
            <div className="offline">
              <span>It's seems you are offline</span>
              Please check the internet connection and do refresh.
            </div>
          )}
        </div>

        <footer>
          <div className="footer-top">
            <ul className="floating">
              <li className="lay-25">
                <div>Help is here</div>
                <ul>
                  <li>
                    <a href="https://www.lagaligoliveaboard.com/payments/">Payments</a>
                  </li>
                  <li>
                    <a href="https://www.lagaligoliveaboard.com/Inclusions-and-Exclusions">Inclusions and Exclusions</a>
                  </li>
                  <li>
                    <a href="https://www.lagaligoliveaboard.com/prices-onboard/">Prices on Board</a>
                  </li>
                  <li>
                    <a href="https://www.lagaligoliveaboard.com/terms-conditions/">Terms and Conditions</a>
                  </li>
                  <li>
                    <a href="https://www.lagaligoliveaboard.com/privacy-policy/">Privacy Policy</a>
                  </li>
                </ul>
              </li>
              {this.state.destinationList.length ? (
                <li className="lay-25">
                  <div>Download Itineraries</div>
                  <ul>
                    {this.state.destinationList.map((value, index) => value.pdf ? (
                      <li key={value.id}>
                        <a href={value.pdf} target="_blank" rel="noreferrer">{value.name} {value.length}D/{value.length - 1}N</a>
                      </li>
                    ) : '')}
                    {/* <li>
                      <a href="https://www.lagaligoliveaboard.com/wp-content/uploads/2023/01/Raja-Ampat-North-8D7N.pdf">Raja Ampat North 8D/7N</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/wp-content/uploads/2023/01/Raja-Ampat-South-9D8N.pdf">Raja Ampat South 9D/8N</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/wp-content/uploads/2023/01/Ultimate-Raja-Ampat-12D11N.pdf">Ultimate Raja Ampat 12D/11N</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/wp-content/uploads/2023/01/Jurassic-Komodo-6D5N.pdf">Jurassic Komodo 6D/5N</a>
                    </li>
                    <li>
                      <a href="https://www.lagaligoliveaboard.com/wp-content/uploads/2023/01/Ultimate-Komodo-8D7N.pdf">Ultimate Komodo 8D/7N</a>
                    </li> */}
                  </ul>
                </li>
              ) : ''}
              <li className="lay-25">
                <div>Connect with us</div>
                <ul>
                  <li>
                    <a href="mailto:info@lagaligoliveaboard.com" className="email"><span><Email /></span>info@lagaligoliveaboard.com</a>
                  </li>
                  <li>
                    <a href="https://wa.me/6281220002025/" className="wa"><span><Wa /></span>+62 812 2000 2025</a>
                  </li>
                </ul>
              </li>
              <li className="lay-25">
                <div>Payment options</div>
                <ul className="floating payment-list">
                  <li>
                    <Visa />
                  </li>
                  <li>
                    <Master />
                  </li>
                  <li>
                    <Paypal />
                  </li>
                  <li>
                    <Wise />
                  </li>
                  <li>
                    <Bitcoin />
                  </li>
                  <li>
                    <Eth />
                  </li>
                  <li>
                    <Xrp />
                  </li>
                </ul>
              </li>
            </ul>
            <div className="foot-btm">
              <LogoWhite />
              <div>
                Keep in touch
                <ul>
                  <li>
                    <a href="https://www.instagram.com/La_Galigo_Liveaboard" target="_blank" rel="noreferrer" className="ig">
                      <Ig />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/LaGaligoLiveaboard" target="_blank" rel="noreferrer" className="fb">
                      <Fb />
                    </a>
                  </li>
                  <li>
                    <a href="https://g.page/r/CbftXt5w1iAJEBM/review" target="_blank" rel="noreferrer" className="gl">
                      <Gl />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@LaGaligoLiveaboard" target="_blank" rel="noreferrer" className="yt">
                      <Yt />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Diving_Indo" target="_blank" rel="noreferrer" className="tw">
                      <Tw />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/la-galigo-liveaboard/" target="_blank" rel="noreferrer" className="in">
                      <In />
                    </a>
                  </li>
                  <li>
                    <a href="https://pin.it/3CYvaow" target="_blank" rel="noreferrer" className="pt">
                      <Pt />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.lagaligoliveaboard.com/news-articles/" target="_blank" rel="noreferrer" className="bl">
                      <Bl />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-btm">
            Copyright &copy; 2023 <a href="https://www.lagaligoliveaboard.com">La Galigo Liveaboard</a>.<br />All Rights Reserved.
          </div>
        </footer>
      </>
    );
  }
}

export default withRouter(App);
